
h2, .h2 {
  font-color: #4E0550;
  font-family: 'Asap';
  font-weight: 500;
}

h3, .h3 {
  color: #0485D1;
  font-weight: 400;
  font-family: 'Asap';
}

.yellowbg {
  background-color: #FBF7F0;
}

.footer {
  color: #fff;
}

.section-border {
  box-shadow:  -5px 10px 35px #717154;
}

.btn-shadow {
  box-shadow:  0px 0px 25px #1D2D35;
}

.prominent {
  color: #0485D1;
  font-weight: 400;
  font-family: 'Asap';
  text-align: left;
}

.prominent2 {
  font-weight: 400;
  font-size: 120%;
}

.prominent3 {
  color: $font-color;
  font-weight: 400;
  font-family: 'Asap';
  text-align: left;
}

mark {
  background: linear-gradient(0.25turn, #fff, #fffad8 2% 90%, #fff);
}

.highlight {
  background-image: linear-gradient(-100deg, rgba(255, 224, 0, 0.1) 15%, rgba(255, 224, 0, 0.8) 35%, rgba(255, 224, 0, 0.1) 95%);
}

.nav-link {
  font-weight: 500;
  font-size: 1.1em;
  font-family: 'Asap';
}

.nav-link a:hover {
  background-color: #FFC512;
}

.fdb-icon {
  width: 7rem;
}

p {
  text-align: justify;
}

p.lead {
  text-align: justify;
}

.pt-5, .py-5 {
    padding-top: 0em !important;
}

.wrapper {
    // min-height: 50vh;
    background-color: #d8332e;
    // padding-bottom: 32px;
}

.navbar-brand img {
    padding: 0;
    height: 64px;
}

.main-header {
    // padding: 6px 0;
    background-color: #d8332e;
    li.nav-item a.nav-link {
        color: white;
    }
    li.nav-item a.nav-link:hover {
        color: white;
        text-decoration: underline;
    }
}

// d8332e rosso
// 1dd7c8 cyan
// f8ab0c giallo
// ffffff white
.homepage-hero {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
    // padding: 7.5rem 0;
    position: relative;
    max-width: 1110px;
    margin: 0 auto;
}

.home-feature {
    // margin-bottom: 64px;
}

.home-product-features {
    background-color: #00acdd;
    margin: 0 auto;
    .features {
        max-width: 600px;
        margin: 0 auto;
    }
}

.home-workshop-gallery {
    .btn-primary {
        color: white;
    }
}

.home-product-app {
    background-color: #d8332e;
    margin: 0 auto;
    color: white;
    .home-product-app-container {
        width: 80%;
        margin: 0 auto;
    }
    .home-product-app-item img.img-fluid {
        height: 150px;
    }
}

.home-workshop-feature {
    background-color: #d8332e;
    margin: 0 auto;
    color: white;
}

.section-large {
    margin: 64px auto;
}

.bg-blue {
    background-color: #00acdd
}

.bg-yellow {
    background-color: #FCE752;
}

.section {
    clear: both
}

.who-feature {
    height: 450px;
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-down(lg) {
    .who-feature {
        height: auto;
    }
}

.round-number {
    border-radius: 24px;
    width: 24px;
    text-align: center;
    background-color: black;
    color: white;
    font-weight: bold;
    font-size: 14px;
    height: 24px;
    display: inline-block;
    margin-right: 12px;
    // font-family: Helvetica, monospaced;
    vertical-align: center;
    padding: 2px 2px 2px 3px;
}

.magenta {
  color: #B30049;
}

.gold-underlined {
  border-bottom: 3px solid #FFC512;
}

.gu {
  border-bottom: 2px solid #FFC512;
}

.magenta-underlined {
  border-bottom: 3px dotted #B30049;
}

.mu {
  border-bottom: 2px solid #B30049;
}

b {
  color: #B30049;
}

ul.repairs li {
  margin-bottom: 1em;
}

.highlight2 {
  background-image: linear-gradient(-100deg, rgba(255, 224, 0, 0.1) 45%, rgba(255, 224, 0, 0.8) 100%, rgba(255, 224, 0, 0.1) 95%);
  padding: 2px;
  padding-left: 8px;
}

.h3feature {
  font-size: 125%;
}

.secnavlink {
  font-size: 75%;
}

#secondary.navbar-nav {
  flex-direction: row;
  font-size: 110%;
}

#products.navbar-nav {
}
