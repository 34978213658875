@mixin font-smoothing($value: false) {
  @if $value {
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing:  subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
