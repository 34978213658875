// popular brands
// https://brandcolors.net
$brand-popular: (
  "amazon":     #ff9900,
  "apple":      #999999,
  "behance":    #1769ff,
  "bitcoin":    #f7931a,
  "dribbble":   #ea4c89,
  "facebook":   #3b5998,
  "github":     #333333,
  "google":     #4285f4,
  "googleplus": #dd4b39,
  "heart":      #df584e,
  "instagram":  #405de6,
  "linkedin":   #007bb5,
  "medium":     #00ab6c,
  "pinterest":  #cb2027,
  "reddit":     #ff4500,
  "rss":        #ff6600,
  "slack":      #6ecadc,
  "snapchat":   #fffc00,
  "twitter":    #55acee,
  "whatsapp":   #4dc247,
  "youtube":    #bb0000,
  "xbrand":     #7d7d7d
);

@each $name, $color in $brand-popular {
  @include text-emphasis-variant(".text-#{$name}", $color);
}
